import { React, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { getPointStats } from '../../helpers/requests/pointInformation'
import { createReplenishment } from '../../helpers/requests/createReplenishment'
import Header from '../../components/Header'
import packageBody from '../../components/DistributionReturn/packageBody'
import Button from '../../components/shared/Button'
import { downloadLabel } from '../../utils/downloadLabel'
import { PRICE_PER_PRODUCT } from '../../constants/prices'

const CreateReturnPackage = () => {
  const [pointInfo, setPointInfo] = useState({})
  const { isLoading } = useFetch(getPointStats, setPointInfo)
  const [quantity, setQuantity] = useState(1)
  const [isCreating, setIsCreating] = useState(false)
  const [order, setOrderId] = useState('')


  const handleQuantityChange = (e) => {
    const { value } = e.target
    if (/^\d+$/.test(value) && Number(value) > 0) {
      setQuantity(Number(value))
    } else if (value === '') {
      setQuantity('')
    }
  }

  const handleUSerOrderId = (e) =>{
    const { value } = e.target
    setOrderId(value)
  }

  const createPackage = async () => {
    setIsCreating(true)
    try {
      const body = packageBody(pointInfo, quantity, order)
      const data = await createReplenishment(body)
      const labelUrl = data?.label?.[0]?.labelUrl
      downloadLabel(labelUrl)
      setQuantity(1)
    } catch (error) {
      return
    } finally {
      setIsCreating(false)
    }
  }

  const totalCost = quantity * PRICE_PER_PRODUCT


  return (
    <>
      <div>
        <Header to="/" name="Retorno" color="bg-purple" qrScannerStyle />
      </div>
      <div className="m-auto w-4/12 items-center justify-center ">
      <div className='my-8 flex items-baseline justify-around'>Nº Guia
        <input
          type="text"
          value={order}
          onChange={handleUSerOrderId}
          className="w-72 border p-2 text-center"
          placeholder="Opcional"
        />
        </div>
        <div className='my-8 flex items-baseline justify-around'>Bultos
        <input
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          className="w-72 border p-2 text-center"
          placeholder="Cantidad de Bultos"
          min="1"
        />
        </div>
        <p>Total: ${totalCost}</p>
        <Button
          color="bg-purple"
          onClick={createPackage}
          inactive={isLoading || totalCost === 0}
          textColor="text-white"
        >
          {isCreating ? 'Creando...' : 'Cerrar Paquete'}
        </Button>
      </div>
    </>
  )
}

export default CreateReturnPackage
