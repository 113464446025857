
export const downloadLabel = (labelUrl) => {
    if (labelUrl) {
      const link = document.createElement('a')
      link.href = labelUrl
      link.download = 'label.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
