import { PRICE_PER_PRODUCT } from '../../constants/prices'

// eslint-disable-next-line react-func/max-lines-per-function
const packageBody = (pointInfo, quantity, order) => {
  const totalCost = quantity * PRICE_PER_PRODUCT

  return {
    lippiOrderId: order,
    warehousePointId: pointInfo?.warehouseInfo?.warehouseId,
    warehouseId: pointInfo?.warehouseInfo?.courierPointId,
    products: [
      {
        code: '',
        name: 'varios productos',
        price: totalCost,
        photos: [''],
        weight: 1,
        quantity
      }
    ],
    companyId: '44',
    cost: totalCost,
    weight: 1,
    volume: 2
  }
}

export default packageBody
