import apiClient from '../../axiosConfig/apiClient'
import { DISTRIBUTION_RETURN_URL } from '../../constants/api'



export const createReplenishment = async (body) => {
  const response = await apiClient.post(DISTRIBUTION_RETURN_URL, body)
  return response.data
}


