import { React, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import BottomNavbar from '../components/BottomNavbar'
import Loader from '../components/shared/Loader'
import SearchBar from '../components/shared/SearchBar'
import { getPointStats } from '../helpers/requests/pointInformation'
import { userDefault } from '../constants/icons'
import ROUTES from '../constants/routes'
import useFetch from '../hooks/useFetch'
import Notifications from '../components/Home/Notifications/Notifications'
import PointStats from '../components/Home/PointStats'

const Home = () => {
  const [pointInfo, setPointInfo] = useState({})
  const navigate = useNavigate()

  const { isLoading, error } = useFetch(getPointStats, setPointInfo)

  return (
    <div className="h-fit min-h-screen w-full flex-col justify-center bg-light-gray text-center">
      <div className="h-fit pb-20 lg:pt-16 lg:pb-0">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col items-center justify-center">
            <div className="flex h-32 w-screen items-center gap-x-4 rounded-bl-3xl bg-pinflag-dark px-5 py-4 text-white lg:h-20">
              {!error && (
                <>
                  <img className="w-16 lg:w-9" src={userDefault} alt="profile" />
                  <p className="font-bold">{pointInfo.warehouseInfo.nombrebodega}</p>
                </>
              )}
            </div>
            <div
              className="absolute top-28 m-auto max-w-md lg:top-24"
              onClick={() => navigate(ROUTES.PACKAGES)}
              role="button"
              tabIndex={0}
            >
              <SearchBar placeholder="Busca un pedido..." />
            </div>
            <Notifications />

            <Link
              to={ROUTES.DISTRIBUTION}
              className="mt-4 block w-24 max-w-full rounded-md bg-blue py-1 text-center text-sm text-white"
            >
              Distribución
            </Link>
            <Link
              to={ROUTES.DISTRIBUTION_RETURN}
              className="mt-4 block w-24 max-w-full rounded-md bg-blue py-1 text-center text-sm text-white"
            >
               Devolución
            </Link>


            <PointStats pointInfo={pointInfo} error={error}/>
          </div>
        )}
        <BottomNavbar />
      </div>
    </div>
  )
}

export default Home
