import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { AuthContext } from './contexts/AuthContext'
import './App.css'
import Interceptors from './Interceptors'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import ROUTES from './constants/routes'
import Ingress from './pages/Ingress'
import ScanProvider from './contexts/ScanContext'
import StorePackage from './pages/Store/StorePackage'
import IdentifyPackage from './pages/Store/IdentifyPackage'
import Deliver from './pages/Deliver'
import PackageDetail from './pages/Packages/PackageDetail'
import SearchPackages from './pages/Packages/SearchPackages'
import ClaimList from './pages/Claim/ClaimList'
import ClaimProvider from './contexts/ClaimContext'
import ClaimPackage from './pages/Claim/ClaimPackage'
import WeighingScan from './pages/Distribution/WeighingScan'
import Weighing from './pages/Distribution/Weighing'
import Return from './pages/DistributionReturn/CreateReturnPackage'

window.addEventListener('load', () => {
  const currentRoute = window.location.pathname
  if (currentRoute.includes('/distribucion/pesaje')) {
    window.location.href = '/distribucion'
  }
})

const App = () => {
  const { auth } = useContext(AuthContext)

  return (
    <Router>
      <Interceptors>
        {auth ? (
          <div className="flex h-screen flex-row text-center font-lexend">
            <ScanProvider>
              <ClaimProvider>
                <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path={ROUTES.INGRESS} element={<Ingress />} />
                <Route path={ROUTES.IDENTIFY_PACKAGE} element={<IdentifyPackage />} />
                <Route path={ROUTES.STORAGE} element={<StorePackage />} />
                <Route path={ROUTES.DELIVER} element={<Deliver />} />
                <Route path={ROUTES.CLAIM} element={<ClaimList />} />
                <Route path={ROUTES.CLAIM_PACKAGE} element={<ClaimPackage />} />
                <Route path={ROUTES.PACKAGES} element={<SearchPackages />} />
                <Route path={ROUTES.PACKAGE_DETAIL} element={<PackageDetail />} />
                <Route path={ROUTES.DISTRIBUTION} element={<WeighingScan />} />
                <Route path={ROUTES.WEIGHING} element={<Weighing />} />
                <Route path={ROUTES.DISTRIBUTION_RETURN} element={<Return />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              </ClaimProvider>
            </ScanProvider>
          </div>
        ) : (
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </Interceptors>
    </Router>
  )
}

export default App
